import { IonButton, useIonAlert } from "@ionic/react";
import { forwardRef, Ref, useImperativeHandle, useState } from "react";
import Spinner from "./Spinner";
import "./Buttons.css";

export interface ButtonProps {
    text: string,
    onClick: Function,
    disabled?: boolean,
    colour?: string,
    class?: string,
    showSpinner?: boolean,
}

export interface ButtonRef {
    stopSpinning: Function,
}

export interface TextButtonProps {
    onClick?: () => void;
    href?: string;
    target?: string;
    rel?: string;
    color?: string;
    size?: any;
    disabled?: boolean;
    style?: React.CSSProperties;
}

export const TextButton: React.FC<TextButtonProps> = ({
    children,
    onClick,
    href,
    target,
    rel,
    color = 'primary',
    size = 'default',
    disabled = false,
    style,
}
) => {
    return (
        <IonButton
            onClick={onClick}
            href={href}
            target={target}
            rel={rel}
            color={color}
            size={size}
            disabled={disabled}
            expand="block"
            fill="clear"
            shape="round"
            className="ion-no-padding text-button"
            style={style}>
            {children}
        </IonButton>
    );
};

export const LinkButton: React.FC<TextButtonProps> = ({
    children,
    onClick,
    href,
    target,
    rel,
    color = 'primary',
    size = 'default',
    disabled = false,
    style,
}
) => {
    return (
        <IonButton
            onClick={onClick}
            href={href}
            target={target}
            rel={rel}
            color={color}
            size={size}
            disabled={disabled}
            fill="clear"
            className="ion-no-padding text-button"
            style={style}>
            {children}
        </IonButton>
    );
};

export const SubmitButton = forwardRef((props: ButtonProps, ref: Ref<ButtonRef>) => {
    const [showSpinner, setShowSpinner] = useState(false);

    useImperativeHandle(ref, () => ({ stopSpinning }));

    function stopSpinning() {
        setShowSpinner(false);
    }

    function click(e: any) {
        if(props.showSpinner ?? true) setShowSpinner(true);
        props.onClick();
    }

    return <IonButton
        color={props.colour || "secondary"}
        size="default"
        disabled={props.disabled || showSpinner}
        className={(props.class ? props.class : '') + " submitButton"}
        onClick={click}>
        {showSpinner
            ? <>
                <div className="text-content hidden">
                    {props.text}
                </div>
                <div className="spinner"><Spinner /></div>
            </>
            : props.text}
    </IonButton>;
});

export const SubmitButtonSquare = forwardRef((props: ButtonProps, ref: Ref<ButtonRef>) => {
    const [showSpinner, setShowSpinner] = useState(false);

    useImperativeHandle(ref, () => ({ stopSpinning }));

    function stopSpinning() {
        setShowSpinner(false);
    }

    function click(e: any) {
        setShowSpinner(true);
        props.onClick();
    }

    return <IonButton
        color={props.colour || "secondary"}
        size="default"
        disabled={props.disabled || showSpinner}
        className={props.class + " submitButtonSquare"}
        onClick={click}>{showSpinner ? <Spinner /> : props.text}</IonButton>;
});

export const EmailButton: React.FC = () => {
    const sendEmail = () => {
        const emailAddress = 'customerservice@trustist.com';
        const subject = 'Trustist Partners Help Request';
        const body = '';

        const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
            subject
        )}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoLink;
    };

    return (
        <IonButton color={"secondary"} onClick={sendEmail}>
            Email Us &#187;
        </IonButton>
    );
};

export interface ConfirmButtonProps extends ButtonProps {
    confirmationHeader?: string;
    confirmationMessage?: string;
    confirmText?: string;
    cancelText?: string;
}

export const ConfirmButton = forwardRef((props: ConfirmButtonProps, ref: Ref<ButtonRef>) => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [presentAlert] = useIonAlert();

    useImperativeHandle(ref, () => ({ stopSpinning }));

    function stopSpinning() {
        setShowSpinner(false);
    }

    async function handleClick() {
        await presentAlert({
            header: props.confirmationHeader || 'Confirm Action',
            message: props.confirmationMessage || 'Are you sure you want to proceed? This action cannot be undone.',
            buttons: [
                {
                    text: props.cancelText || 'Cancel',
                    role: 'cancel'
                },
                {
                    text: props.confirmText || 'Confirm',
                    role: 'confirm',
                    handler: () => {
                        setShowSpinner(true);
                        props.onClick();
                    }
                }
            ]
        });
    }

    return (
        <IonButton
            color={props.colour || "danger"}
            size="default"
            disabled={props.disabled || showSpinner}
            className={(props.class ? props.class : '') + " submitButton"}
            onClick={handleClick}
        >
            {showSpinner
                ? <>
                    <div className="text-content hidden">
                        {props.text}
                    </div>
                    <div className="spinner"><Spinner /></div>
                </>
                : props.text}
        </IonButton>
    );
});
