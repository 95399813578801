import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonItemGroup, IonPage, IonRow, IonTitle, IonToolbar, IonButtons, IonBackButton } from '@ionic/react';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import "./InvoiceDetails.css";
import { Routes } from '../../Routes';

import { GetInvoiceAsync, Invoice, SendInvoiceToXero, TakeInvoicePayment } from '../../lib';
import PageSpinner from '../controls/PageSpinner';

export default function InvoiceDetails() {
    const { userId } = useParams<{ userId: string }>();
    const { invoiceId } = useParams<{ invoiceId: string }>();
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice>();
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const isFetching = useRef(false);
    const isProcessingPayment = useRef(false);

    const sendInvoiceToXero = async () => {
        await SendInvoiceToXero(userId, invoiceId);
        refreshInvoice();
    };

    const takePayment = async () => {
        if (isProcessingPayment.current) return;
        isProcessingPayment.current = true;
        setShowSpinner(true);
        try {
            await TakeInvoicePayment(userId, invoiceId);
            refreshInvoice();
        } finally {
            setShowSpinner(false);
            isProcessingPayment.current = false;
        }
    };

    const canTakePayment = () => {
        if (!selectedInvoice) return false;
        
        // Only show for Pending or Failed status
        if (selectedInvoice.status !== "Pending" && selectedInvoice.status !== "Failed") return false;
        
        // Check if there are any unpaid items with outstanding amount
        const unpaidAmount = selectedInvoice.amountToPay ?? 0;
            
        return unpaidAmount > 0;
    };

    const refreshInvoice = () => {
        if (isFetching.current === true) return;
        isFetching.current = true;
        setShowSpinner(true);
        getInvoice(userId, invoiceId);
    };

    const getInvoice = (userId: string, invoiceId: string) => GetInvoiceAsync(userId, invoiceId).then(response => {
        setSelectedInvoice(response!);
        setShowSpinner(false);
        isFetching.current = false;
    });

    const formatDate = (dateString: string) => format(new Date(dateString), 'dd MMM yyyy HH:mm');

    useEffect(refreshInvoice, []);

    if (!selectedInvoice) return <PageSpinner />;

    return (selectedInvoice && <>
        <IonPage>
            <IonHeader className='ion-no-border' >
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={Routes.UserInvoices(userId)} text="Back to Invoices" />
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonTitle className='invoiceMainTitle'>Invoice {selectedInvoice?.number}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid className='invoiceDetailsText'>
                    <IonRow>
                        <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2' ></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>Trustist</IonCol>
                        <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4' ><b>Invoice Number</b></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>Blake House,</IonCol>
                        <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>{selectedInvoice?.number}</IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>66 Bootham,</IonCol>
                        <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'><b>Invoice Date</b></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>York</IonCol>
                        <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>{selectedInvoice?.created && formatDate(selectedInvoice?.created.toString())}</IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>YO30 7BZ</IonCol>
                        <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'><b>Supply Date</b></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
                        <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>{selectedInvoice?.start && formatDate(selectedInvoice?.start.toString())} - {selectedInvoice?.end && formatDate(selectedInvoice?.end.toString())}</IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
                        <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'><b>Xero Invoice ID</b></IonCol>
                    </IonRow>
                    <IonRow >
                        <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
                        <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        {selectedInvoice?.xeroInvoiceId && <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>{selectedInvoice?.xeroInvoiceId}</IonCol>}
                        {!selectedInvoice?.xeroInvoiceId && <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>
                            <IonButton onClick={() => sendInvoiceToXero()}>Send to Xero</IonButton>
                        </IonCol>}
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
                        <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        {canTakePayment() && 
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>
                                <IonButton onClick={() => takePayment()}>Take Payment</IonButton>
                            </IonCol>
                        }
                    </IonRow>
                    <br />
                    <br />
                    <IonItemGroup hidden={showSpinner} style={{ width: "80%", margin: "auto" }}>
                        <IonRow className='invoiceBorder'>
                            <IonCol size="1"></IonCol>
                            <IonCol size="2"><b>Date</b></IonCol>
                            <IonCol size='4'><b>Description</b></IonCol>
                            <IonCol size='1'><b>Amount</b></IonCol>
                            <IonCol size='1'><b>VAT</b></IonCol>
                            <IonCol size='1'><b>To Pay</b></IonCol>
                            <IonCol size="1"></IonCol>
                        </IonRow>
                        {selectedInvoice?.invoiceItems?.map((item, index) => {
                            return (
                                <IonRow className='invoiceBorder' key={item.id}>
                                    <IonCol size="1"></IonCol>
                                    <IonCol size='2'>{formatDate(item.itemDate.toString())}</IonCol>
                                    <IonCol size='4'>{item.description}</IonCol>
                                    <IonCol size='1'>{item.amount.toFixed(2)}</IonCol>
                                    <IonCol size='1'>{item.tax.toFixed(2)}</IonCol>
                                    <IonCol size='1'>{item.amountOutstanding.toFixed(2)}</IonCol>
                                    <IonCol size="1"></IonCol>
                                </IonRow>
                            );
                        })}
                        <IonRow>
                            <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
                            <IonCol sizeXs='3' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'>Subtotal</IonCol>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>{selectedInvoice.subtotal.toFixed(2)}</IonCol>
                        </IonRow>
                        <IonRow >
                            <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
                            <IonCol className='invoiceBorder' sizeXs='3' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'>TOTAL VAT 20%</IonCol>
                            <IonCol className='invoiceBorder' sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>{selectedInvoice.tax.toFixed(2)}</IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
                            <IonCol sizeXs='3' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'><b>TOTAL</b></IonCol>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>
                                <b>{selectedInvoice.total.toFixed(2)}</b>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
                            <IonCol sizeXs='3' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'>Amount To Pay</IonCol>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>{selectedInvoice.amountToPay.toFixed(2)}</IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
                            <IonCol sizeXs='3' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'>Amount Paid</IonCol>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>{selectedInvoice.amountPaid.toFixed(2)}</IonCol>
                        </IonRow>
                    </IonItemGroup>
                    <IonRow>

                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    </>);

};