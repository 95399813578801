import { Address, ApiFileDownload, ApiGet, ApiPost } from "..";

export type OnboardingData = {
    steps: Map<string, Step>,
    bankAccount?: OnboardingBankAccount,
    terms: boolean,
    requiresManualIntervention: boolean,
    businessDetails: BusinessDetails,
    applicant: Applicant,
    media: OnboardMedia[],
    completion?: Completion,
};

export const StepNames = {
    GettingStarted: "gettingStarted",
    Questionnaire: "questionnaire",
    DirectDebitMandate: "directDebitMandate",
    BankAccount: "bankAccount",
    AboutBusiness: "aboutBusiness",
    NominateApplicant: "nominateApplicant",
    DirectorCheck: "directorCheck",
    AdditionalInformation: "additionalInfo",
    CompaniesHouse: "companiesHouse",
    BigBusiness: "bigBusiness",
    Complete: "complete",
    OnboardSession: "onboardSession",
    /*TermsAndConditions: "termsAndConditions"*/
} as const;

export const OrgTypes = {
    SoleTrader: "soleTrader",
    LimtedCompany: "limitedCompany",
    LimitedLiabilityPartnership: "limitedLiabilityPartnership",
    PublicSectorOrganisation: "publicSectorOrganisation",
    Foundation: "foundation",
    Charity: "charity",
    Trust: "trust",
    Other: "other",
} as const;

export const OrgTypesDisplayText = new Map<string, string>([
    [OrgTypes.SoleTrader, "Sole Trader"],
    [OrgTypes.LimtedCompany, "Limited Company (e.g. PLC)"],
    [OrgTypes.LimitedLiabilityPartnership, "Limited Liability Partnership"],
    [OrgTypes.PublicSectorOrganisation, "Public Sector Organisation"],
    [OrgTypes.Foundation, "Foundation"],
    [OrgTypes.Charity, "Charity"],
    [OrgTypes.Trust, "Trust"],
    [OrgTypes.Other, "Other"],
]);

export interface Step {
    created: Date;
    lastUpdated: Date;
    status: StepStatus,
};

export enum StepStatus {
    Pending = 10,
    Started = 20,
    Waiting = 30,
    Failed = 50,
    Complete = 100,
}

export interface Completion {
    merchantId: string,
    completionDate: Date,
}

export type Applicant = {
    role: string,
    phoneNumber: string,
};

export type BusinessDetails = {
    orgType?: string,
    orgTypeOther?: string,
    companiesHouseNumber: string,
    beneficiaries: Beneficiary[],
    directors: Director[],
    address?: Address,
    businessSize: BusinessSize,
    companyName: string,
    website: string,
    additionalInformation: AdditionalInformation,
    onCompaniesHouse?: boolean,
};

export type BusinessSize = {
    overTenMillionTurnover?: boolean,
    overFiveMillionBalance?: boolean,
    overFiftyEmployees?: boolean,
};

export type Beneficiary = {
    id: string,
    firstName: string,
    lastName: string,
    address: Address,
    isApplicant: boolean,
    shareLink: string,
    onboardSession?: OnboardSession,
    birthMonth: number,
    birthYear: number,
};

export type Director = {
    id: string,
    name: string,
    address: Address,
    birthMonth: number,
    birthYear: number,
    isApplicant?: boolean,
    shareLink?: string,
    onboardSession?: OnboardSession,
};

export type OnboardSession = {
    created: string,
    updated: string,
    media: OnboardMedia[],
    status: OnboardStatus,
    isIdentityConfirmed: boolean,
};

export type AdditionalInformation = {
    customerComplaintsProcess: string,
    customerComplaintsProcessOther: string,
    natureOfBusiness: string,
    vatNumber: string,
};

export type OnboardingBankAccount = {
    name: string,
    accountHolderName: string | null,
    accountNumber: string | null,
    sortCode: string | null,
    iban: string | null,
    currency: string | null,
};

export enum OnboardMediaType {
    CertificateOfIncorporation =10,
    ArticlesOfAssociation = 20,
    GroupStructureChart = 30,
    AmlPolicy = 40,
    AntiBriberyPolicy = 50,
    DataProtectionPolicy = 60,
    SecurityPolicy = 70,
    UTR = 80,
    Certificate = 90,
    Other = 100,
    Identity = 110,
    ProofOfAddress = 120,
};

export enum OnboardStatus {
    Pending = 10,
    Started = 20,
    Waiting = 30,
    Failed = 50,
    Complete = 100,
};

export type OnboardMedia = {
    id: string,
    description?: string,
    fileName?: string,
    contentType?: string,
    type: number,
};

export const GetOnboardingData = async (accountId: string): Promise<OnboardingData | null> => {
    let response = await ApiGet(`onboarding/${accountId}`);
    return response.status === 200
        ? {
            ...response.data,
            steps: new Map(Object.entries(response.data.stepData)),
        }
        : null;
};

export const PromoteOnboardingToMerchant = async (accountId: string): Promise<boolean> => {
    var response = await ApiPost(`onboarding/${accountId}/promotion`, {});
    return response.status === 200;
}

export async function DownloadMedia(accountId: string, mediaId: string): Promise<boolean> {
    await ApiFileDownload(`onboarding/${accountId}/media/${mediaId}`);
    return true;
}

export async function DownloadBeneficiaryMedia(accountId: string, beneficiaryId: string, mediaId: string): Promise<boolean> {
    await ApiFileDownload(`onboarding/${accountId}/beneficiaries/${beneficiaryId}/media/${mediaId}`);
    return true;
}

export async function DownloadDirectorMedia(accountId: string, directorId: string, mediaId: string): Promise<boolean> {
    await ApiFileDownload(`onboarding/${accountId}/directors/${directorId}/media/${mediaId}`);
    return true;
}

export const FetchExternalData = async (accountId: string, beneficiaryId: string): Promise<boolean> => {
    var response = await ApiPost(`onboarding/${accountId}/beneficiaries/${beneficiaryId}/fetchexternaldata`, {});
    return response.status === 200;
}

export const FetchExternalDataForDirector = async (accountId: string, directorId: string): Promise<boolean> => {
    var response = await ApiPost(`onboarding/${accountId}/directors/${directorId}/fetchexternaldata`, {});
    return response.status === 200;
}
