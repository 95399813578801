import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import { alarmOutline } from "ionicons/icons";
import { Route, useParams } from "react-router";
import CardOnboarding from "./cardOnboarding/CardOnboarding";
import InvoiceHistory from "./invoicing/InvoiceHistory";
import OnboardingDetails from "./OnboardingDetails";
import PaymentHistory from "./payments/PaymentHistory";
import RegularPaymentsPage from "./payments/RegularPayments";
import MerchantToolsPage from "./payments/MerchantTools";
import User from "./User";
import "./Users.css";
import { Routes } from "../Routes";
import InvoiceDetails from "./invoicing/InvoiceDetails";

interface ViewParams {
    userId: string,
};

export default function UsersNav() {
    const { userId } = useParams<ViewParams>();

    return <>
        <IonTabs>
            <IonRouterOutlet>
                <Route path="/users/:userId/" component={User} exact />
                <Route path="/users/:userId/OnboardingDetails" component={OnboardingDetails} exact />
                <Route path="/users/:userId/PaymentHistory" component={PaymentHistory} exact />
                <Route path="/users/:userId/RegularPayments" component={RegularPaymentsPage} exact />
                <Route path="/users/:userId/invoices" component={InvoiceHistory} exact />
                <Route path="/users/:userId/invoices/:invoiceId" component={InvoiceDetails} exact />
                <Route path="/users/:userId/Tools" component={MerchantToolsPage} exact />
                <Route path="/users/:userId/CardOnboarding" component={CardOnboarding} exact />
            </IonRouterOutlet>

            <IonTabBar id="userTabBar" slot="bottom">
                <IonTabButton tab="Onboarding" href={`/users/${userId}/OnboardingDetails`}>
                    <IonIcon src="/assets/icon/userCheck.svg" />
                    <IonLabel className="tabText">Onboarding Details</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Payment History" href={`/users/${userId}/PaymentHistory`}>
                    <IonIcon src="/assets/icon/shoppingCart.svg"></IonIcon>
                    <IonLabel className="tabText">Payment History</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Regular Payments" href={`/users/${userId}/RegularPayments`}>
                    <IonIcon style={{color:'#D4D4D4'}} src={alarmOutline}></IonIcon>
                    <IonLabel className="tabText">Regular Payments</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Home" href={`/users/${userId}/`}>
                    <IonIcon src="/assets/icon/homeIcon.svg"/>
                    <IonLabel className="tabText">User Home</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Cards" href={`/users/${userId}/CardOnboarding`}>
                    <IonIcon src="/assets/icon/creditCardIcon.svg" />
                    <IonLabel className="tabText">Card Onboarding</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Invoices" href={Routes.UserInvoices(userId)}>
                    <IonIcon src="/assets/icon/bookIcon.svg"></IonIcon>
                    <IonLabel className="tabText">Invoice History</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Tools" href={`/users/${userId}/Tools`}>
                    <IonIcon src="/assets/icon/toolsIcon.svg"></IonIcon>
                    <IonLabel className="tabText">Tools</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    </>;
};