export const Routes = {
    Home: "/",
    User: (id: string) => `/users/${id}`,
    Partner: (id: string) => `/partners/${id}`,
    PartnerInvitations: (id: string) => `${Routes.Partner(id)}/invitations`,
    PartnerInvitation: (userid: string, invitationId: string) => `${Routes.PartnerInvitations(userid)}/${invitationId}`,
    Instructions: "/admin/instructions",
    Instruction: (id: string) => `/admin/instructions/${id}`,
    UserInvoices: (userId: string) => `/users/${userId}/invoices`,
    UserInvoice: (userId: string, invoiceId: string) => `/users/${userId}/invoices/${invoiceId}`
}