import { ApiGet, ApiPost } from "..";

export type Invoice = {
    id: string,
    accountId: string,
    created: Date,
    start: Date,
    end: Date,
    status: string,
    number: string,
    merchantName: string,
    invoiceItems: InvoiceItem[],
    total: number,
    subtotal: number,
    tax: number,
    amountToPay: number,
    amountPaid: number,
    xeroInvoiceId: string,

    // sometimes this, for some reason 
    amount: number,
};

export type InvoiceItem = {
    id: string,
    itemDate: Date,
    description: string,
    amount: number,
    tax: number,
    amountOutstanding: number,
};

export type InvoiceList = {
    data: Invoice[],
    continuation: string | undefined,
};

export const GetInvoiceAsync = async (accountId: string, invoiceId: string): Promise<Invoice | null> => {
    var response = await ApiGet(`accounts/${accountId}/invoices/${invoiceId}`);
    return response.status === 200 ? response?.data : null;
};

export const ListInvoices = async (userId: string, continuation: string, pageSize?: number, status?: string, invoiceNumber?: string, start?: string, end?: string): Promise<InvoiceList> => {
    var url = `invoices/${userId}?`;
    if (userId > '') url += `userId=${userId}&`;
    if (continuation > '') url += `continuation=${continuation}&`;
    if (pageSize) url += `pageSize=${pageSize}&`;
    if (status) url += `status=${status}&`;
    if (invoiceNumber) url += `invoiceNumber=${invoiceNumber}&`;
    if (start) url += `start=${start}&`;
    if (end) url += `end=${end}&`;
    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    }

    return { data: new Array<Invoice>(), continuation: undefined };
};

export const SendInvoiceToXero = async (accountId: string, invoiceId: string): Promise<boolean> => {
    var response = await ApiPost(`accounts/${accountId}/invoices/${invoiceId}/sendToXero`, {});
    return response.status === 200;
}

export const TakeInvoicePayment = async (accountId: string, invoiceId: string): Promise<boolean> => {
    var response = await ApiPost(`accounts/${accountId}/invoices/${invoiceId}/takePayment`, {});
    return response.status === 200;
}

export const RaiseInvoiceAsync = async (
    accountId: string, 
    startDate: Date | null, 
    endDate: Date | null
) => {
    var response = await ApiPost(
        `accounts/${accountId}/raiseInvoice`,
        { startDate, endDate }
    );

    return response.status === 200;
};

export const PreviewInvoiceAsync = async (accountId: string): Promise<InvoiceItem[]> => {
    var response = await ApiGet(`accounts/${accountId}/invoices/preview`);
    if (response?.status === 200) return response.data;
    else throw response?.data || 'Error';
}

export const RunUninvoicedItemsInvoicingAsync = async (
    startDate: Date | null, 
    endDate: Date | null
): Promise<boolean> => {
    var response = await ApiPost(`uninvoiceditems/run`, { startDate, endDate });
    return response.status === 200;
}

