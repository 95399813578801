import { IonBackButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonPage, IonRow, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { ButtonRef, LinkButton, SubmitButton, ConfirmButton } from "../controls/Buttons";
import { format } from "date-fns";
import { GetInstructionAsync, Instruction, InstructionPartyType, InstructionStatus, IgnoreInstructionAsync, ResubmitInstructionAsync, UpdateInstructionPayloadAsync } from "../../lib/data/MessagingData";
import { useHistory } from "react-router";
import { GetUserIdByMerchantKey } from "../../lib";
import { Routes } from "../../Routes";
import { InstructionHandler } from './handlers';

export default function InstructionDetailPage() {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [instruction, setInstruction] = useState<Instruction>();
    const [loading, setLoading] = useState(true);
    const submitButtonRef = useRef<ButtonRef>(null);
    const ignoreButtonRef = useRef<ButtonRef>(null);
    const lockApiCalls = useRef(false);
    const [present] = useIonToast();

    useEffect(() => {
        loadInstruction();
    }, [id]);

    async function loadInstruction() {
        if (lockApiCalls.current === true) return;
        try {
            lockApiCalls.current = true;
            setLoading(true);
            const result = await GetInstructionAsync(id);
            setInstruction(result);
        } catch (error: any) {
            present({
                message: 'Failed to load instruction',
                duration: 3000,
                position: 'bottom',
                color: 'danger'
            });
            console.error(error);
        } finally {
            setLoading(false);
            lockApiCalls.current = false;
        }
    }

    async function handleResubmit() {
        if (!instruction) return;
        try {
            await ResubmitInstructionAsync(instruction.id);
            present({
                message: 'Instruction has been resubmitted',
                duration: 3000,
                position: 'bottom',
                color: 'success'
            });
            history.push(Routes.Instructions);
        } catch (error: any) {
            present({
                message: 'Failed to resubmit instruction: ' + (error.message || 'Unknown error'),
                duration: 3000,
                position: 'bottom',
                color: 'danger'
            });
        } finally {
            submitButtonRef.current?.stopSpinning();
        }
    }

    async function handleIgnore() {
        if (!instruction) return;
        try {
            await IgnoreInstructionAsync(instruction.id);
            present({
                message: 'Instruction has been ignored',
                duration: 3000,
                position: 'bottom',
                color: 'success'
            });
            history.push(Routes.Instructions);
        } catch (error: any) {
            present({
                message: 'Failed to ignore instruction: ' + (error.message || 'Unknown error'),
                duration: 3000,
                position: 'bottom',
                color: 'danger'
            });
        } finally {
            ignoreButtonRef.current?.stopSpinning();
        }
    }

    async function navigateToEntity(entityType: string, entityId: string) {
        switch (entityType.toLowerCase()) {
            case "account":
                history.push(`/users/${entityId}`);
                break;
            case "merchant":
                const userId = await GetUserIdByMerchantKey(entityId);
                if (userId) {
                    history.push(`/users/${userId}`);
                }
                break;
        }
    }

    function canNavigateToEntity(entityType: string): boolean {
        return ["account", "merchant"].includes(entityType.toLowerCase());
    }

    const handleUpdatePayload = async (newPayload: any) => {
        try {
            await UpdateInstructionPayloadAsync(instruction!.id, newPayload);
            await ResubmitInstructionAsync(instruction!.id);
            present({
                message: 'Instruction resubmitted successfully',
                duration: 3000,
                position: 'bottom',
                color: 'success'
            });
            await loadInstruction();
        } catch (error) {
            present({
                message: 'Failed to update instruction',
                duration: 3000,
                position: 'bottom',
                color: 'danger'
            });
            console.error(error);
        }
    };

    if (!instruction) {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref={Routes.Instructions} />
                        </IonButtons>
                        <IonTitle>Instruction Details</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonItem>
                        <IonLabel className="ion-text-center">
                            {loading ? "Loading instruction..." : "Instruction not found"}
                        </IonLabel>
                    </IonItem>
                </IonContent>
            </IonPage>
        );
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={Routes.Instructions} />
                    </IonButtons>
                    <IonTitle>{instruction.type}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel>
                                    <h2>Status</h2>
                                    <p>{instruction.status}</p>
                                </IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel>
                                    <h2>Timing</h2>
                                    <p>Created: {format(instruction.created, "dd/MM/yyyy HH:mm:ss")}</p>
                                    <p>Updated: {format(instruction.updated, "dd/MM/yyyy HH:mm:ss")}</p>
                                </IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel>
                                    <h2>Parties</h2>
                                    <p>From: {InstructionPartyType[instruction.sender.party]}{instruction.sender.system ? ` (${instruction.sender.system})` : ''}</p>
                                    <p>To: {InstructionPartyType[instruction.recipient.party]}{instruction.recipient.system ? ` (${instruction.recipient.system})` : ''}</p>
                                </IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    {instruction.entityType && (
                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel>
                                        <h2>Entity</h2>
                                        <p>
                                            {instruction.entityType}: {
                                                canNavigateToEntity(instruction.entityType) ? (
                                                    <LinkButton
                                                        onClick={() => navigateToEntity(instruction.entityType!, instruction.entityId!)}
                                                        color="secondary"
                                                    >
                                                        {instruction.entityId}
                                                    </LinkButton>
                                                ) : instruction.entityId
                                            }
                                        </p>
                                    </IonLabel>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    )}

                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel>
                                    <h2>Retries</h2>
                                    <p>Count: {instruction.retryCount} of {instruction.maxRetryCount}</p>
                                </IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    {instruction.errorMessage && (
                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel>
                                        <h2>Error</h2>
                                        <p className="error-message">{instruction.errorMessage}</p>
                                    </IonLabel>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    )}

                    <InstructionHandler 
                        instruction={instruction}
                        onUpdatePayload={handleUpdatePayload}
                    />

                    {instruction.status === InstructionStatus.Failed && (
                        <IonRow>
                            <IonCol>
                                <SubmitButton
                                    text="Resubmit Instruction"
                                    onClick={handleResubmit}
                                    ref={submitButtonRef}
                                />
                                <ConfirmButton
                                    text="Ignore Failure"
                                    onClick={handleIgnore}
                                    ref={ignoreButtonRef}
                                    confirmationHeader="Ignore Failed Instruction"
                                    confirmationMessage="Are you sure you want to ignore this failed instruction? This will mark it as resolved without resubmitting."
                                    confirmText="Ignore"
                                />
                            </IonCol>
                        </IonRow>
                    )}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
} 