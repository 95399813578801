import React, { Suspense } from 'react';
import { Instruction } from '../../../lib/data/MessagingData';

export interface InstructionHandlerProps {
    instruction: Instruction;
    onUpdatePayload: (newPayload: any) => Promise<void>;
}

// Cache for lazy-loaded handler components
const handlerCache: Record<string, React.LazyExoticComponent<React.ComponentType<InstructionHandlerProps>>> = {};

const getHandler = (instructionType: string) => {
    if (instructionType in handlerCache) {
        return handlerCache[instructionType];
    }

    const LazyHandler = React.lazy(() => 
        import(`./${instructionType}`)
            .then(module => ({
                default: module[`${instructionType}Handler`]
            }))
            .catch(() => ({
                default: () => null
            }))
    );

    handlerCache[instructionType] = LazyHandler;
    return LazyHandler;
};

export const InstructionHandler = ({
    instruction,
    onUpdatePayload
}: InstructionHandlerProps) => {
    const Handler = React.useMemo(
        () => getHandler(instruction.type),
        [instruction.type]
    );

    return (
        <Suspense fallback={null}>
            <Handler instruction={instruction} onUpdatePayload={onUpdatePayload} />
        </Suspense>
    );
}; 
