import { ApiGet, ApiPost, ApiPut } from "../util/Api";
import { AppendQuerystring } from "../util/Querystring";

export enum InstructionStatus {
    Created = "Created",
    InterventionRequired = "Intervention Required",
    ReadyForResubmission = "Ready For Resubmission",
    Complete = "Complete",
    Failed = "Failed",
    FailedIgnore = "Failed (Ignore)"
}

export interface InstructionParty {
    party: InstructionPartyType;
    system: string;
}

export enum InstructionPartyType {
    Reviews,
    Payments,
    Hubspot,
    Admin,
    Rfyt
}

export interface InstructionEntity {
    type: string;
    id: string;
}

export interface Instruction {
    id: string;
    type: string;
    status: InstructionStatus;
    created: Date;
    updated: Date;
    sender: InstructionParty;
    recipient: InstructionParty;
    entityType?: string;
    entityId?: string;
    retryCount: number;
    maxRetryCount: number;
    errorMessage?: string;
    payload?: string;
    additionalProperties?: { [key: string]: string };
}

export interface InstructionList {
    data: Instruction[];
    page: number;
    pageSize: number;
    totalCount: number;
    hasMore: boolean;
}

export interface InstructionFilter {
    type?: string;
    status?: string;
    entityType?: string;
    entityId?: string;
    startDate?: Date;
    endDate?: Date;
}

// Helper function to convert status string to enum
function convertStatusToEnum(status: string): InstructionStatus {
    // Remove spaces and make it match the enum key format
    const normalizedStatus = status.replace(/\s+/g, '');
    return Object.entries(InstructionStatus).find(
        ([key]) => key === normalizedStatus
    )?.[1] as InstructionStatus || InstructionStatus.Created;
}

export async function GetInstructionAsync(id: string): Promise<Instruction> {
    const response = await ApiGet(`instructions/${id}`);
    
    if (response.status === 200) {
        return {
            ...response.data,
            status: convertStatusToEnum(response.data.status),
            created: new Date(response.data.created),
            updated: new Date(response.data.updated)
        };
    }
    throw response.data;
}

export async function GetInstructionsAsync(filter?: InstructionFilter, page: number = 1): Promise<InstructionList> {
    let url = "instructions";
    
    url = AppendQuerystring(url, "page", page.toString());
    
    if (filter) {
        if (filter.type) url = AppendQuerystring(url, "type", filter.type);
        if (filter.status) url = AppendQuerystring(url, "status", filter.status);
        if (filter.entityType) url = AppendQuerystring(url, "entityType", filter.entityType);
        if (filter.entityId) url = AppendQuerystring(url, "entityId", filter.entityId);
        if (filter.startDate) url = AppendQuerystring(url, "startDate", filter.startDate.toISOString());
        if (filter.endDate) url = AppendQuerystring(url, "endDate", filter.endDate.toISOString());
    }
    
    const response = await ApiGet(url);
    
    if (response.status === 200) {
        // Convert date strings to Date objects and status to enum
        response.data.data = response.data.data.map((instruction: any) => ({
            ...instruction,
            status: convertStatusToEnum(instruction.status),
            created: new Date(instruction.created),
            updated: new Date(instruction.updated)
        }));
        return response.data;
    }
    throw response.data;
}

export async function IgnoreInstructionAsync(id: string): Promise<void> {
    const response = await ApiPost(`instructions/${id}/ignore`);
    if (response.status !== 200) {
        throw response.data;
    }
}

export async function ResubmitInstructionAsync(id: string): Promise<void> {
    const response = await ApiPost(`instructions/${id}/resubmit`);
    if (response.status !== 200) {
        throw response.data;
    }
}

export async function UpdateInstructionPayloadAsync(id: string, payload: any): Promise<void> {
    const response = await ApiPut(`instructions/${id}/payload`, payload);
    if (response.status !== 200) {
        throw response.data;
    }
}