import { IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonMenuButton, IonPage, IonRow, IonSpinner, IonToolbar } from '@ionic/react';
import { checkmarkCircleOutline, syncOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import ConfirmationButton from '../components/ConfirmationButton';
import { DownloadBeneficiaryMedia, DownloadDirectorMedia, DownloadMedia, FetchExternalData, FetchExternalDataForDirector, GetOnboardingData, GetUserAsync, OnboardingData, OnboardMediaType, OnboardStatus, OrgTypes, PromoteOnboardingToMerchant, Step, StepNames, StepStatus, User, OnboardMedia } from '../lib';
import { formatDateStringToLocal } from '../lib/util/Dates';
import './OnboardingDetails.css';
import { useParams } from 'react-router';

type PageState = {
    user: User | null,
    onboarding: OnboardingData | null,
};

interface DisplayStepItem {
    displayName: string,
};

const DisplaySteps = new Map<string, DisplayStepItem>([
    [StepNames.AboutBusiness, { displayName: "About Your Business" }],
    [StepNames.DirectorCheck, { displayName: "Director Verification" }],
    [StepNames.BankAccount, { displayName: "Bank Account Selection" }],
    [StepNames.DirectDebitMandate, { displayName: "Direct Debit Setup" }],
    [StepNames.AdditionalInformation, { displayName: "Additional Information" }],
    [StepNames.BigBusiness, { displayName: "Big Business Questionnaire" }],
    [StepNames.Complete, { displayName: "Complete" }],
    [StepNames.DirectDebitMandate, { displayName: "Direct Debit Mandate" }],
    [StepNames.GettingStarted, { displayName: "Getting Started" }],
    [StepNames.NominateApplicant, { displayName: "Applicant Nomination" }],
    [StepNames.CompaniesHouse, { displayName: "Companies House" }],
    [StepNames.OnboardSession, { displayName: "Identity Check" }],
]);

const DisplayMediaNames = new Map<OnboardMediaType, string>([
    [OnboardMediaType.AmlPolicy, "Anti-Money Laundering Policy"],
    [OnboardMediaType.AntiBriberyPolicy, "Anti-Bribery Policy"],
    [OnboardMediaType.ArticlesOfAssociation, "Articles of Association"],
    [OnboardMediaType.Certificate, "License/Certificate"],
    [OnboardMediaType.CertificateOfIncorporation, "Certificate of Incorporation"],
    [OnboardMediaType.DataProtectionPolicy, "Data Protection Policy"],
    [OnboardMediaType.GroupStructureChart, "Group Structure Chart"],
    [OnboardMediaType.Other, "Other"],
    [OnboardMediaType.SecurityPolicy, "Security Policy"],
    [OnboardMediaType.UTR, "UTR"],
]);

interface ViewParams {
    userId: string,
};

export default function OnboardingPage() {
    const { userId } = useParams<ViewParams>();
    const lockApiCalls = useRef(false);
    const [pageState, setPageState] = useState<PageState>({
        user: null,
        onboarding: null,
    });
    const [isPromoting, setIsPromoting] = useState(false);
    const [promoteMessage, setPromoteMessage] = useState<string | null>(null);
    const [isComplyCubeUpdating, setIsComplyCubeUpdating] = useState(false);

    function updatePageState(name: keyof PageState, value: PageState[keyof PageState]) {
        setPageState((prevState) => {
            if (!prevState) return { [name]: value } as PageState;
            return { ...prevState, [name]: value };
        });
    };

    function pageStateIsEmpty() {
        return pageState.user === null &&
            pageState.onboarding === null;
    }

    async function getDataAsync() {
        try {
            if (userId) {
                lockApiCalls.current = true;

                const promises = [
                    GetUserAsync(userId).then(user => updatePageState('user', user)),
                    GetOnboardingData(userId).then(onBoardingStatus => updatePageState('onboarding', onBoardingStatus)),
                ];

                await Promise.all(promises);
            }
        }
        finally {
            lockApiCalls.current = false;
        }
    }

    useEffect(() => {
        // two levels of preventing subsequent queries needed here -
        // one for if a call has gone out but the state is not yet populated (lockApiCalls)
        // and another if all the api calls have gone out but states are in process of being populated (pageStateIsEmpty)
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        if (pageStateIsEmpty()) {
            getDataAsync();
        } else {
            lockApiCalls.current = false;
        }
    }, [pageState])

    useEffect(() => {
        if (userId) {
            // force the state to fire into the associated useEffect regardless of the data 
            setPageState(prevState => ({
                ...prevState,
                user: null,
                onboarding: null,
            }));
        }
    }, [userId]);

    async function promoteToMerchant() {
        if (isPromoting) return;

        try {
            setIsPromoting(true);
            var result = await PromoteOnboardingToMerchant(pageState.user?.id!);
            setPromoteMessage(result ? "Promotion successful" : "Promotion failed");
        }
        finally {
            setIsPromoting(false);
            getDataAsync();
        }
    }

    const onboardingState = () => {
        if (pageState.user?.merchant) return <span style={{ color: '#17DA36' }}>Complete</span>;
        if (pageState.onboarding?.steps?.get(StepNames.Complete)?.status === StepStatus.Complete) {
            return <span style={{ color: '#EA1313' }}>Ready to be approved</span>;
        }
        return <span style={{ color: '#ffa700' }}>In progress</span>
    }

    // STEPS
    const stepStatus = (step: Step) => {
        if (step.status === StepStatus.Complete) return <span className={"onboardingStatusGreen"}>Complete</span>;
        if (step.status === StepStatus.Failed) return <span color="danger">Failed</span>;
        if (step.status === StepStatus.Started) return <span className={"onboardingStatusYellow"}>In Progress</span>;
        return <span className={"onboardingStatusRed"}>Not Started</span >;
    }

    let stepArray: JSX.Element[] = [];

    if (pageState.onboarding?.steps) {
        pageState.onboarding.steps.forEach((step, key) => {
            const createdString = step.created;
            const lastUpdatedString = step.lastUpdated;
            const createdDate = new Date(createdString);
            const lastUpdatedDate = new Date(lastUpdatedString);

            stepArray.push(
                <tr className='tableRowSpacing' key={key}>
                    <td style={{ fontWeight: 'bold' }}>{DisplaySteps.get(key)?.displayName}</td>
                    <td>{stepStatus(step)}</td>
                    <td className='adminBlueDataText'>{createdDate.toLocaleString("en-GB")}</td>
                    <td className='adminBlueDataText'>{lastUpdatedDate.toLocaleString("en-GB")}</td>
                </tr>
            );
        });
    }

    // DOCUMENTS
    const cleanFileName = (fullPath: string) => {
        let parts = fullPath.split('/');
        return parts[parts.length - 1];
    }

    let documentArray: JSX.Element[] = [];
    const docTypes: number[] = [OnboardMediaType.Other, OnboardMediaType.UTR];
    if (pageState.onboarding && pageState.user) {
        pageState.onboarding.media.filter(m => docTypes.includes(m.type)).forEach((m) => documentArray.push(
            <tr className='tableRowSpacing' key={m.id}>
                <td style={{ fontWeight: 'bold' }}>{m.description}</td>
                <td className='adminBlueDataText pointer'>{m.fileName &&
                    <span className="pointer" onClick={() => DownloadMedia(pageState.user!.id, m.id)}><u>{cleanFileName(m.fileName)}</u></span>}</td>
                <td style={{ fontWeight: 'bold' }}>{(m.type === 80) ? "UTR" : "Other"}</td>
            </tr>
        ));
    }

    let bigBusinessDocArray: JSX.Element[] = [];
    const bigBusinessDocTypes: number[] = [
        OnboardMediaType.AmlPolicy,
        OnboardMediaType.AntiBriberyPolicy,
        OnboardMediaType.ArticlesOfAssociation,
        OnboardMediaType.CertificateOfIncorporation,
        OnboardMediaType.DataProtectionPolicy,
        OnboardMediaType.GroupStructureChart,
        OnboardMediaType.SecurityPolicy,
    ];
    if (pageState.onboarding && pageState.user) {
        pageState.onboarding.media.filter(m => bigBusinessDocTypes.includes(m.type)).forEach((m) => bigBusinessDocArray.push(
            <tr className='tableRowSpacing' key={m.id}>
                <td style={{ fontWeight: "bold" }}>{DisplayMediaNames.get(m.type)}</td>
                <td className='adminBlueDataText pointer'>{m.fileName &&
                    <span className="pointer" onClick={() => DownloadMedia(pageState.user!.id, m.id)}><u>{cleanFileName(m.fileName)}</u></span>}</td>
            </tr>
        ));
    }

    let certificatesArray: JSX.Element[] = [];
    if (pageState.onboarding && pageState.user) {
        pageState.onboarding.media.filter(m => m.type === OnboardMediaType.Certificate).forEach((m) => certificatesArray.push(
            <tr className='tableRowSpacing' key={m.id}>
                <td style={{ fontWeight: "bold" }}>{m.description}</td>
                <td className='adminBlueDataText pointer'>{m.fileName &&
                    <span className="pointer" onClick={() => DownloadMedia(pageState.user!.id, m.id)}><u>{cleanFileName(m.fileName)}</u></span>}</td>
            </tr>
        ));
    }

    // BENEFICIARIES
    const OnboardStatusColour = (status?: OnboardStatus): string => {
        if (!status) return "onboardingStatusRed";

        switch (status) {
            case OnboardStatus.Complete: return "onboardingStatusGreen";
            case OnboardStatus.Failed: return "onboardingStatusRed";
            default: return "onboardingStatusYellow";
        };
    };

    const OnboardStatusDisplayText = (status?: OnboardStatus): string => {
        if (!status) return "Not Started";

        switch (status) {
            case OnboardStatus.Complete: return "Complete";
            case OnboardStatus.Failed: return "Failed";
            case OnboardStatus.Pending: return "Pending";
            case OnboardStatus.Started: return "Started";
            case OnboardStatus.Waiting: return "Waiting";
            default: return "Unknown";
        };
    };

    const UpdateFromComplyCube = async (beneficiaryId: string) => {
        if (!userId) return;

        setIsComplyCubeUpdating(true);

        try {
            await FetchExternalData(userId, beneficiaryId);
        }
        finally {
            setIsComplyCubeUpdating(false);
        }
    }

    const UpdateDirectorFromComplyCube = async (directorId: string) => {
        if (!userId) return;

        setIsComplyCubeUpdating(true);

        try {
            await FetchExternalDataForDirector(userId, directorId);
        }
        finally {
            setIsComplyCubeUpdating(false);
        }
    }

    return (
        <IonPage>

            <IonHeader className='ion-no-border'>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>
                    <IonGrid>
                        <IonRow className='onboardingTitleRows'>
                            <IonCol size='auto'>
                                <h1 id='onboardingTitle'>Onboarding -</h1>
                            </IonCol>
                            <IonCol size='auto'>
                                <h1 id='onboardingUsersName'>{pageState?.user?.firstName}, {pageState?.user?.lastName}</h1>
                            </IonCol>
                        </IonRow>

                    </IonGrid>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen >

                <IonCard style={{ marginBottom: '25px' }}>
                    <IonCardContent>
                        <h2 className='adminSubHeadingBlue'>Merchant Status</h2>
                        <table style={{ width: '50%' }}>
                            <tbody>
                                <tr className='tableRowSpacing'>
                                    <td><b>Status:</b></td>
                                    <td>{onboardingState()}</td>
                                </tr>
                                <tr className='tableRowSpacing'>
                                    <td><b>Approval Date:</b></td>
                                    <td className='adminBlueDataText'>
                                        {pageState.onboarding?.completion?.completionDate &&
                                            new Date(pageState.onboarding.completion.completionDate).toLocaleString("en-GB", {
                                                month: "numeric",
                                                day: "numeric",
                                                year: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                                second: "numeric",
                                                hour12: true,
                                            })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {(pageState.user && (pageState.user.merchant === false)) &&
                            <ConfirmationButton
                                buttonLabel="Approve Merchant"
                                alertHeader="Approve Merchant"
                                alertMessage="Are you sure you want to proceed?"
                                onConfirm={promoteToMerchant}
                                isLoading={isPromoting} />
                        }
                        {promoteMessage && <p>{promoteMessage}</p>}
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardContent>
                        <div style={{ height: '225px' }}>
                            <h2 className='adminSubHeadingBlue'>Merchant Details</h2>
                            <table style={{ width: '90%' }}>
                                <tbody>
                                    <tr className='tableRowSpacing'>
                                        <td><b>First Name:</b></td>
                                        <td className='adminBlueDataText adminDataSpacing'>{pageState.user?.firstName}</td>
                                        <td><b>Contact Number:</b></td>
                                        <td className='adminBlueDataText'>{pageState.onboarding?.applicant?.phoneNumber}</td>
                                    </tr>
                                    <tr className='tableRowSpacing'>
                                        <td><b>Last Name:</b></td>
                                        <td className='adminBlueDataText'>{pageState.user?.lastName}</td>
                                        <td><b>Address:</b></td>
                                        <td className='adminBlueDataText' rowSpan={3}>
                                            {pageState.onboarding?.businessDetails?.address?.addressLine1}<br />
                                            {pageState.onboarding?.businessDetails?.address?.addressLine2 ? pageState.onboarding?.businessDetails?.address?.addressLine2 + <br /> : ''}
                                            {pageState.onboarding?.businessDetails?.address?.city}<br />
                                            {pageState.onboarding?.businessDetails?.address?.country}<br />
                                            {pageState.onboarding?.businessDetails?.address?.postalCode}<br />
                                        </td>
                                    </tr>
                                    <tr className='tableRowSpacing'>
                                        <td><b>Business Name:</b></td>
                                        <td className='adminBlueDataText'>{pageState.onboarding?.businessDetails?.companyName}</td>
                                    </tr>
                                    <tr className='tableRowSpacing'>
                                        <td><b>Email:</b></td>
                                        <td className='adminBlueDataText'>{pageState.user?.emails[0]}</td>
                                    </tr>
                                    <tr className='tableRowSpacing'>
                                        <td><b>Currency:</b></td>
                                        <td className='adminBlueDataText'>{pageState.onboarding?.bankAccount?.currency}</td>
                                    </tr>
                                    <tr className='tableRowSpacing'>
                                        <td><b>IBAN:</b></td>
                                        <td className='adminBlueDataText'>{pageState.onboarding?.bankAccount?.iban}</td>
                                    </tr>
                                    <tr className='tableRowSpacing'>
                                        <td><b>Account Number:</b></td>
                                        <td className='adminBlueDataText'>{pageState.onboarding?.bankAccount?.accountNumber}</td>
                                    </tr>
                                    <tr className='tableRowSpacing'>
                                        <td><b>Sort Code:</b></td>
                                        <td className='adminBlueDataText'>{pageState.onboarding?.bankAccount?.sortCode}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </IonCardContent>
                </IonCard>

                <IonRow>
                    <IonCol size='8'>
                        <IonCard>
                            <IonCardContent>
                                <div style={{ height: '490px' }}>
                                    <h2 className='adminSubHeadingBlue'>Onboarding Progress</h2>
                                    <table style={{ width: '100%' }}>
                                        <thead className='headerUnderline'>
                                            <tr style={{ textAlign: 'left' }} className='tableRowSpacing'>
                                                <th style={{ fontWeight: "normal" }}>Stage</th>
                                                <th style={{ fontWeight: "normal" }}>Status</th>
                                                <th style={{ fontWeight: "normal" }}>Date Started</th>
                                                <th style={{ fontWeight: "normal" }}>Last Update</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {stepArray}
                                        </tbody>
                                    </table>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>

                    <IonCol size='4'>
                        <IonCard>
                            <IonCardContent>
                                <div style={{ height: '490px' }}>
                                    <h2 className='adminSubHeadingBlue'>Documents Uploaded</h2>
                                    <table style={{ width: '100%' }}>
                                        <thead className='headerUnderline'>
                                            <tr className='tableRowSpacing' style={{ textAlign: 'left' }}>
                                                <th style={{ fontWeight: "normal" }}>Description</th>
                                                <th style={{ fontWeight: "normal" }}>File</th>
                                                <th style={{ fontWeight: "normal" }}>Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documentArray}
                                        </tbody>
                                    </table>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonCard>
                            <IonCardContent>
                                <div style={{ height: '350px' }}>
                                    <h2 className='adminSubHeadingBlue'>Questionnaire</h2>
                                    <table width={"100%"}>
                                        <thead className='headerUnderline'>
                                            <tr className='tableRowSpacing' style={{ textAlign: 'left' }} >
                                                <th style={{ fontWeight: "normal" }}>Question</th>
                                                <th style={{ fontWeight: "normal" }}>Answer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='tableRowSpacing'>
                                                <td><b>Turnover greater than £10M</b></td>
                                                <td className='adminBlueDataText'>{pageState.onboarding?.businessDetails?.businessSize && pageState.onboarding?.businessDetails?.businessSize?.overTenMillionTurnover === true ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr className='tableRowSpacing'>
                                                <td><b>Equity on balance sheet exceeds £5M</b></td>
                                                <td className='adminBlueDataText'>{pageState.onboarding?.businessDetails?.businessSize && pageState.onboarding?.businessDetails?.businessSize?.overFiveMillionBalance === true ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr className='tableRowSpacing'>
                                                <td><b>More than 50 employees</b></td>
                                                <td className='adminBlueDataText'>{pageState.onboarding?.businessDetails?.businessSize && pageState.onboarding?.businessDetails?.businessSize?.overFiftyEmployees === true ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr className='tableRowSpacing'>
                                                <td><b>Sole Trader</b></td>
                                                <td className='adminBlueDataText'>{pageState.onboarding?.businessDetails && pageState.onboarding?.businessDetails?.orgType === OrgTypes.SoleTrader ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr className='tableRowSpacing'>
                                                <td><b>Customer complaints process</b></td>
                                                <td className='adminBlueDataText'>{pageState.onboarding?.businessDetails?.additionalInformation
                                                    && (pageState.onboarding?.businessDetails?.additionalInformation?.customerComplaintsProcessOther
                                                        ?? pageState.onboarding?.businessDetails?.additionalInformation?.customerComplaintsProcess)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>

                    <IonCol>
                        <IonCard>
                            <IonCardContent>
                                <div style={{ height: '350px' }}>
                                    <h2 className='adminSubHeadingBlue'>Big Business Documents</h2>
                                    <table width={"100%"}>
                                        <thead className='headerUnderline'>
                                            <tr className='tableRowSpacing' style={{ textAlign: 'left' }} >
                                                <th style={{ fontWeight: "normal" }}>Type</th>
                                                <th style={{ fontWeight: "normal" }}>File</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bigBusinessDocArray}
                                        </tbody>
                                    </table>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>

                {certificatesArray.length > 0 &&
                    <IonCard style={{ marginBottom: '25px' }}>
                        <IonCardContent>
                            <h2 className='adminSubHeadingBlue'>Certificates/Licenses</h2>
                            <table width={"100%"}>
                                <thead className='headerUnderline'>
                                    <tr className='tableRowSpacing' style={{ textAlign: 'left' }} >
                                        <th style={{ fontWeight: "normal" }}>Description</th>
                                        <th style={{ fontWeight: "normal" }}>File</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {certificatesArray}
                                </tbody>
                            </table>
                        </IonCardContent>
                    </IonCard>
                }

                <IonCard>
                    <IonCardContent>
                        <h2 className='adminSubHeadingBlue'>POSC Verification</h2>
                        <table width={"100%"}>
                            <thead className='headerUnderline'>
                                <tr className='tableRowSpacing' style={{ textAlign: 'left' }} >
                                    <th style={{ fontWeight: "normal", width: "18%" }}>Name</th>
                                    <th style={{ fontWeight: "normal", width: "5%" }}>Status</th>
                                    <th style={{ fontWeight: "normal", width: "18%" }}>Date Started</th>
                                    <th style={{ fontWeight: "normal", width: "18%" }}>Last Update</th>
                                    <th style={{ fontWeight: "normal", width: "18%" }}>ID Document</th>
                                    <th style={{ fontWeight: "normal", width: "18%" }}>Address Document</th>
                                    <th style={{ fontWeight: "normal", width: "5%" }}>ComplyCube</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pageState.onboarding?.businessDetails?.beneficiaries &&
                                    pageState.onboarding?.businessDetails?.beneficiaries
                                        //.filter(beneficiary => beneficiary.isApplicant !== true)
                                        .map(beneficiary => {
                                            var identityMedia = beneficiary?.onboardSession?.media?.find((m: OnboardMedia) => m.type === OnboardMediaType.Identity);
                                            var poaMedia = beneficiary?.onboardSession?.media?.find((m: OnboardMedia) => m.type === OnboardMediaType.ProofOfAddress);

                                            return <tr className='tableRowSpacing' key={beneficiary.id}>
                                                <td style={{ fontWeight: "bold" }}>
                                                    {beneficiary.firstName} {beneficiary.lastName}
                                                    {beneficiary.isApplicant && <span style={{ marginLeft: '5px', color: '#666', fontSize: '0.9em' }}>(Applicant)</span>}
                                                </td>
                                                <td className='adminBlueDataText'>
                                                    <span className={OnboardStatusColour(beneficiary.onboardSession?.status)}>
                                                        {OnboardStatusDisplayText(beneficiary.onboardSession?.status)}
                                                    </span>
                                                </td>
                                                <td className='adminBlueDataText'>{beneficiary.onboardSession && formatDateStringToLocal(beneficiary.onboardSession?.created)}</td>
                                                <td className='adminBlueDataText'>{beneficiary.onboardSession && formatDateStringToLocal(beneficiary.onboardSession?.updated)}</td>
                                                <td className='adminBlueDataText'>
                                                    {identityMedia?.fileName &&
                                                        <span className="pointer" onClick={() => DownloadBeneficiaryMedia(pageState.user!.id, beneficiary.id, identityMedia!.id)}>
                                                            <u>{cleanFileName(identityMedia.fileName)}</u>
                                                        </span>}
                                                </td>
                                                <td className='adminBlueDataText'>
                                                    {poaMedia?.fileName &&
                                                        <span className="pointer" onClick={() => DownloadBeneficiaryMedia(pageState.user!.id, beneficiary.id, poaMedia!.id)}>
                                                            <u>{cleanFileName(poaMedia.fileName)}</u>
                                                        </span>}
                                                </td>
                                                <td style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                                    {beneficiary.onboardSession?.status === OnboardStatus.Complete && (                                                        
                                                        <IonButton
                                                            size='large'
                                                            fill='clear'
                                                            style={{ marginTop: "13px" }}
                                                            title="Update from ComplyCube"
                                                            className="icon-only-button"
                                                            onClick={() => UpdateFromComplyCube(beneficiary.id)}
                                                            disabled={isComplyCubeUpdating}>
                                                            {isComplyCubeUpdating ? <IonSpinner name="circular" /> :
                                                                <IonIcon
                                                                    size='small'
                                                                    icon={checkmarkCircleOutline}
                                                                    color='success'
                                                                />}
                                                        </IonButton>
                                                    )}

                                                    {(!beneficiary.onboardSession || beneficiary.onboardSession?.status !== OnboardStatus.Complete) && (
                                                        <IonButton
                                                            size='large'
                                                            fill='clear'
                                                            style={{ marginTop: "13px" }}
                                                            title="Update from ComplyCube"
                                                            className="icon-only-button"
                                                            onClick={() => UpdateFromComplyCube(beneficiary.id)}
                                                            disabled={isComplyCubeUpdating}>
                                                            {isComplyCubeUpdating ? <IonSpinner name="circular" /> : <IonIcon icon={syncOutline} />}
                                                        </IonButton>
                                                    )}
                                                </td>
                                            </tr>
                                        })}
                            </tbody>
                        </table>
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardContent>
                        <h2 className='adminSubHeadingBlue'>Director Verification</h2>
                        <table width={"100%"}>
                            <thead className='headerUnderline'>
                                <tr className='tableRowSpacing' style={{ textAlign: 'left' }} >
                                    <th style={{ fontWeight: "normal", width: "18%" }}>Name</th>
                                    <th style={{ fontWeight: "normal", width: "5%" }}>Status</th>
                                    <th style={{ fontWeight: "normal", width: "18%" }}>Date Started</th>
                                    <th style={{ fontWeight: "normal", width: "18%" }}>Last Update</th>
                                    <th style={{ fontWeight: "normal", width: "18%" }}>ID Document</th>
                                    <th style={{ fontWeight: "normal", width: "18%" }}>Address Document</th>
                                    <th style={{ fontWeight: "normal", width: "5%" }}>ComplyCube</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pageState.onboarding?.businessDetails?.directors &&
                                    pageState.onboarding?.businessDetails?.directors.map(director => {
                                        var identityMedia = director?.onboardSession?.media?.find((m: OnboardMedia) => m.type === OnboardMediaType.Identity);
                                        var poaMedia = director?.onboardSession?.media?.find((m: OnboardMedia) => m.type === OnboardMediaType.ProofOfAddress);

                                        return <tr className='tableRowSpacing' key={director.id}>
                                            <td style={{ fontWeight: "bold" }}>
                                                {director.name}
                                                {director.isApplicant && <span style={{ marginLeft: '5px', color: '#666', fontSize: '0.9em' }}>(Applicant)</span>}
                                            </td>
                                            <td className='adminBlueDataText'>
                                                <span className={OnboardStatusColour(director.onboardSession?.status)}>
                                                    {OnboardStatusDisplayText(director.onboardSession?.status)}
                                                </span>
                                            </td>
                                            <td className='adminBlueDataText'>{director.onboardSession && formatDateStringToLocal(director.onboardSession?.created)}</td>
                                            <td className='adminBlueDataText'>{director.onboardSession && formatDateStringToLocal(director.onboardSession?.updated)}</td>
                                            <td className='adminBlueDataText'>
                                                {identityMedia?.fileName &&
                                                    <span className="pointer" onClick={() => DownloadDirectorMedia(pageState.user!.id, director.id, identityMedia!.id)}>
                                                        <u>{cleanFileName(identityMedia.fileName)}</u>
                                                    </span>}
                                            </td>
                                            <td className='adminBlueDataText'>
                                                {poaMedia?.fileName &&
                                                    <span className="pointer" onClick={() => DownloadDirectorMedia(pageState.user!.id, director.id, poaMedia!.id)}>
                                                        <u>{cleanFileName(poaMedia.fileName)}</u>
                                                    </span>}
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                                {director.onboardSession?.status === OnboardStatus.Complete && (                                                        
                                                    <IonButton
                                                        size='large'
                                                        fill='clear'
                                                        style={{ marginTop: "13px" }}
                                                        title="Update from ComplyCube"
                                                        className="icon-only-button"
                                                        onClick={() => UpdateDirectorFromComplyCube(director.id)}
                                                        disabled={isComplyCubeUpdating}>
                                                        {isComplyCubeUpdating ? <IonSpinner name="circular" /> :
                                                            <IonIcon
                                                                size='small'
                                                                icon={checkmarkCircleOutline}
                                                                color='success'
                                                            />}
                                                    </IonButton>
                                                )}

                                                {(!director.onboardSession || director.onboardSession?.status !== OnboardStatus.Complete) && (
                                                    <IonButton
                                                        size='large'
                                                        fill='clear'
                                                        style={{ marginTop: "13px" }}
                                                        title="Update from ComplyCube"
                                                        className="icon-only-button"
                                                        onClick={() => UpdateDirectorFromComplyCube(director.id)}
                                                        disabled={isComplyCubeUpdating}>
                                                        {isComplyCubeUpdating ? <IonSpinner name="circular" /> : <IonIcon icon={syncOutline} />}
                                                    </IonButton>
                                                )}
                                            </td>
                                        </tr>
                                    })}
                            </tbody>
                        </table>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};